import React, { useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";

import {
  makeStyles,
  Grid,
  Avatar,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";

import { logout } from "../Store/Auth/actions";
import { getUsername, getAccessRight, getName } from "../Store/Auth/selector";

import Logo from "../Assets/Logo.png";
import Person from "../Assets/FotoProfil.png";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    top: 0,
    width: "100%",
    borderBottom: "2px solid",
    borderBottomColor: theme.palette.normalTextfield,
    background: "white",
    zIndex: 2,
  },
  grid: {
    height: 80,
  },
  clickable: {
    cursor: "pointer",
    paddingRight: 10,
  },
  image: {
    position: "absolute",
    width: "6%",
  },
  headerTitle: {
    color: theme.palette.blue,
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogout = () => {
    props.logout();
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const header = (props) => {
    if (props === "/account") {
      return "Account";
    } else if (props === "/user") {
      return "User";
    } else if (props === "/customer-support") {
      return "Customer Support";
    } else if (props === "/testimoni") {
      return "Testimoni";
    } else if (props === "/artikel") {
      return "Artikel";
    } else if (props === "/gallery") {
      return "Gallery";
    } else if (props === "/video") {
      return "Video";
    } else if (props === "/klinik") {
      return "Klinik";
    } else if (props === "/dokter") {
      return "Dokter";
    } else if (props === "/tindakan") {
      return "Tindakan";
    } else if (props === "/promo") {
      return "Promo";
    } else if (props === "/faq") {
      return "FAQ";
    } else if (props === "/appointment") {
      return "Appointment";
    } else if (props === "/pembayaran") {
      return "Pembayaran";
    } else if (props === "/poin") {
      return "Poin";
    } else if (props === "/voucher") {
      return "Voucher";
    } else if (props === "/voucherownership") {
      return "Voucher Ownership";
    } else if (props === "/chat") {
      return "Chat";
    } else if (props === "/messagecenter") {
      return "Message Center";
    } else if (props === "/report") {
      return "Report";
    }
    return "Setting";
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid
          className={classes.grid}
          style={{ padding: 15 }}
          item
          xs={4}
          md={4}
          container
          alignItems="flex-end"
        >
          <Typography
            variant="h2"
            color="textSecondary"
            className={classes.headerTitle}
          >
            {header(location.pathname)}
          </Typography>
        </Grid>
        <Grid
          className={classes.grid}
          item
          xs={6}
          md={6}
          container
          justify="flex-end"
          alignItems="center"
        >
          {/* <img src={Logo} alt="inventory" className={classes.image} /> */}
        </Grid>
        <Grid
          className={classes.grid}
          item
          xs={2}
          md={2}
          container
          justify="center"
          alignItems="center"
          direction="row"
        >
          <Avatar
            className={classes.clickable}
            src={Person}
            onClick={openMenu}
          />
          <div>
            <Typography variant="body1" color="primary">
              {getUsername()}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {getAccessRight()}
            </Typography>
          </div>
        </Grid>
      </Grid>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        getContentAnchorEl={null}
      >
        <MenuItem>{getName()}</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default connect(null, { logout })(Header);
