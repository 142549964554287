import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import { LinearProgress } from "@material-ui/core";

import routes from "./Routes/routes";
import AuthLayout from "./Components/AuthLayout";
import BaseLayout from "./Components/BaseLayout";
import Login from "./Components/Login/Login";
import ForgotPassword from "./Components/Login/ForgotPassword";
import ChangePassword from "./Components/Login/ChangePassword";

import useCekHakAkses from "./Utils/cekHakAkses";

const AccountRoute = lazy(() => import("./Components/Account/AccountRoute"));
const UserRoute = lazy(() => import("./Components/User/UserRoute"));
const CSRoute = lazy(() => import("./Components/CS/CustomerServiceRoute"));
const ArtikelRoute = lazy(() => import("./Components/Artikel/ArtikelRoute"));
const TestimoniRoute = lazy(() =>
  import("./Components/Testimoni/TestimoniRoute")
);
const GalleryRoute = lazy(() => import("./Components/Gallery/GalleryRoute"));
const StoryRoute = lazy(() => import("./Components/Story/StoryRoute"));
const KlinikRoute = lazy(() => import("./Components/Klinik/KlinikRoute"));
const VideoRoute = lazy(() => import("./Components/Video/VideoRoute"));
const DokterRoute = lazy(() => import("./Components/Dokter/DokterRoute"));
const TindakanRoute = lazy(() => import("./Components/Tindakan/TindakanRoute"));
const PromoRoute = lazy(() => import("./Components/Promo/PromoRoute"));
const BannerPromoRoute = lazy(() =>
  import("./Components/BannerPromo/BannerPromoRoute")
);
const OnboardingLoginRoute = lazy(() => import("./Components/OnboardingLogin/OnboardingLoginRoute"))
const FAQRoute = lazy(() => import("./Components/FAQ/FAQRoute"));
const AsuransiRoute = lazy(() => import("./Components/Asuransi/AsuransiRoute"));
const AppointmentRoute = lazy(() =>
  import("./Components/Appointment/AppointmentRoute")
);
const PembayaranRoute = lazy(() =>
  import("./Components/Pembayaran/PembayaranRoute")
);
const PoinRoute = lazy(() => import("./Components/Poin/PoinRoute"));
const VoucherRoute = lazy(() => import("./Components/Voucher/VoucherRoute"));
const VoucherOwnershipRoute = lazy(() =>
  import("./Components/VoucherOwnership/VoucherOwnershipRoute")
);
const ChatRoute = lazy(() => import("./Components/Chat/ChatRoute"));
const MessageCenterRoute = lazy(() =>
  import("./Components/MessageCenter/MessageCenterRoute")
);
const SettingRoute = lazy(() => import("./Components/Setting/SettingRoute"));
const ReportRoute = lazy(() => import("./Components/Report/ReportRoute"));

export function useAllowedRoute() {
  const cekRole = useCekHakAkses();
  const location = useLocation();

  const allowedRoutes = [];
  if (cekRole("a1")) {
    allowedRoutes.push(routes.ACCOUNT);
  }
  if (cekRole("b1")) {
    allowedRoutes.push(routes.USER);
  }
  if (cekRole("x1")) {
    allowedRoutes.push(routes.CUSTOMERSERVICE);
  }
  if (cekRole("c1")) {
    allowedRoutes.push(routes.TESTIMONI);
  }
  if (cekRole("d1")) {
    allowedRoutes.push(routes.ARTIKEL);
  }
  if (cekRole("e1")) {
    allowedRoutes.push(routes.GALLERY);
  }
  if (cekRole("f1")) {
    allowedRoutes.push(routes.VIDEO);
  }
  if (cekRole("g1")) {
    allowedRoutes.push(routes.KLINIK);
  }
  if (cekRole("h1")) {
    allowedRoutes.push(routes.DOKTER);
  }
  if (cekRole("i1")) {
    allowedRoutes.push(routes.TINDAKAN);
  }
  if (cekRole("j1")) {
    allowedRoutes.push(routes.PROMO);
  }
  if (cekRole("k1")) {
    allowedRoutes.push(routes.FAQ);
  }
  if (cekRole("l1")) {
    allowedRoutes.push(routes.APPOINTMENT);
  }
  if (cekRole("m1")) {
    allowedRoutes.push(routes.PEMBAYARAN);
  }
  if (cekRole("n1")) {
    allowedRoutes.push(routes.POIN);
  }
  if (cekRole("o1")) {
    allowedRoutes.push(routes.VOUCHER);
  }
  if (cekRole("p1")) {
    allowedRoutes.push(routes.VOUCHEROWNERSHIP);
  }
  if (cekRole("q1")) {
    allowedRoutes.push(routes.CHAT);
  }
  if (cekRole("r1") && (cekRole("r2") || cekRole("r3"))) {
    allowedRoutes.push(routes.MESSAGECENTER);
  }
  if (
    cekRole("s1") &&
    (cekRole("s2") ||
      cekRole("s8") ||
      cekRole("s12") ||
      cekRole("s14") ||
      cekRole("s19") ||
      cekRole("s24") ||
      cekRole("s29") ||
      cekRole("s34") ||
      cekRole("s39") ||
      cekRole("s44") || 
      cekRole("s49") ||
      cekRole("s54"))
  ) {
    allowedRoutes.push(routes.REPORT);
    allowedRoutes.push(routes.SETTING);
  }
  if (cekRole("t1")) {
    allowedRoutes.push(routes.STORY);
  }
  if (cekRole("u1")) {
    allowedRoutes.push(routes.BANNER_PROMO);
  }
  if (cekRole("v1")) {
    allowedRoutes.push(routes.ONBOARDINGLOGIN);
  }
  if (cekRole("w1")) {
    allowedRoutes.push(routes.ASURANSI);
  }
  const isCurrentRouteAllowed = allowedRoutes.some((route) => {
    const regexp = new RegExp(route);
    return regexp.test(location.pathname);
  });
  // jika route saat ini gaada akses
  if (!isCurrentRouteAllowed) {
    // maka kasih route yang dibolehkan pertama
    return allowedRoutes[0];
  }
  // kalau dibolehkan ,kasih aja route saat ini
  return location.pathname;
}

const PrivateRoute = ({ children, ...rest }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  return (
    <Route
      {...rest}
      render={() =>
        isLoggedIn ? children : <Redirect to={{ pathname: routes.LOGIN }} />
      }
    />
  );
};

const Routing = (props) => {
  const cekRole = useCekHakAkses();
  const location = useLocation();
  const allowedRoute = useAllowedRoute();

  return (
    <Switch>
      <Route path="/auth">
        <AuthLayout>
          <Suspense
            fallback={
              <div>
                <LinearProgress />
              </div>
            }
          >
            <Switch>
              <Route exact path={routes.LOGIN} component={() => <Login />} />
              <Route
                exact
                path={routes.FORGOTPASS}
                component={() => <ForgotPassword />}
              />
              <Route
                exact
                path={routes.CHANGEPASS + "/:token"}
                component={() => <ChangePassword />}
              />
              <Redirect to={routes.LOGIN} />
            </Switch>
          </Suspense>
        </AuthLayout>
      </Route>
      <PrivateRoute path="/">
        <BaseLayout>
          <Suspense
            fallback={
              <div>
                <LinearProgress />
              </div>
            }
          >
            <Switch>
              {cekRole("a1") && (
                <Route
                  path={routes.ACCOUNT}
                  component={() => <AccountRoute />}
                />
              )}
              {cekRole("b1") && (
                <Route
                  exact
                  path={routes.USER}
                  component={() => <UserRoute />}
                />
              )}
              {cekRole("x1") && (
                <Route
                  exact
                  path={routes.CUSTOMERSERVICE}
                  component={() => <CSRoute />}
                />
              )}
              {cekRole("c1") && (
                <Route
                  exact
                  path={routes.TESTIMONI}
                  component={() => <TestimoniRoute />}
                />
              )}
              {cekRole("d1") && (
                <Route
                  exact
                  path={routes.ARTIKEL}
                  component={() => <ArtikelRoute />}
                />
              )}
              {cekRole("e1") && (
                <Route
                  exact
                  path={routes.GALLERY}
                  component={() => <GalleryRoute />}
                />
              )}
              {cekRole("f1") && (
                <Route
                  exact
                  path={routes.KLINIK}
                  component={() => <KlinikRoute />}
                />
              )}
              {cekRole("g1") && (
                <Route
                  exact
                  path={routes.VIDEO}
                  component={() => <VideoRoute />}
                />
              )}
              {cekRole("h1") && (
                <Route
                  exact
                  path={routes.DOKTER}
                  component={() => <DokterRoute />}
                />
              )}
              {cekRole("i1") && (
                <Route
                  exact
                  path={routes.TINDAKAN}
                  component={() => <TindakanRoute />}
                />
              )}
              {cekRole("j1") && (
                <Route
                  exact
                  path={routes.PROMO}
                  component={() => <PromoRoute />}
                />
              )}
              {cekRole("u1") && (
                <Route
                  exact
                  path={routes.BANNER_PROMO}
                  component={() => <BannerPromoRoute />}
                />
              )}
              {cekRole("v1") && (
                <Route
                  exact
                  path={routes.ONBOARDINGLOGIN}
                  component={() => <OnboardingLoginRoute />}
                />
              )}
              {cekRole("k1") && (
                <Route exact path={routes.FAQ} component={() => <FAQRoute />} />
              )}
              {cekRole("w1") && (
                <Route 
                  exact
                  path={routes.ASURANSI}
                  component={() => <AsuransiRoute />}
                />
              )}
              {cekRole("l1") && (
                <Route
                  exact
                  path={routes.APPOINTMENT}
                  component={() => <AppointmentRoute />}
                />
              )}
              {cekRole("m1") && (
                <Route
                  exact
                  path={routes.PEMBAYARAN}
                  component={() => <PembayaranRoute />}
                />
              )}
              {cekRole("n1") && (
                <Route
                  exact
                  path={routes.POIN}
                  component={() => <PoinRoute />}
                />
              )}
              {cekRole("o1") && (
                <Route
                  exact
                  path={routes.VOUCHER}
                  component={() => <VoucherRoute />}
                />
              )}
              {cekRole("p1") && (
                <Route
                  exact
                  path={routes.VOUCHEROWNERSHIP}
                  component={() => <VoucherOwnershipRoute />}
                />
              )}
              {cekRole("q1") && (
                <Route
                  exact
                  path={routes.CHAT}
                  component={() => <ChatRoute />}
                />
              )}
              {cekRole("r1") && (cekRole("r2") || cekRole("r3")) && (
                <Route
                  exact
                  path={routes.MESSAGECENTER}
                  component={() => <MessageCenterRoute />}
                />
              )}
              {cekRole("s1") &&
                (cekRole("s2") ||
                  cekRole("s8") ||
                  cekRole("s12") ||
                  cekRole("s14") ||
                  cekRole("s19") ||
                  cekRole("s24") ||
                  cekRole("s29") ||
                  cekRole("s34") ||
                  cekRole("s39") ||
                  cekRole("s44") ||
                  cekRole("s49") ||
                  cekRole("s54")) && (
                  <Route
                    exact
                    path={routes.SETTING}
                    component={() => <SettingRoute />}
                  />
                )}
                {cekRole("s1") &&
                  (cekRole("s2") ||
                    cekRole("s8") ||
                    cekRole("s12") ||
                    cekRole("s14") ||
                    cekRole("s19") ||
                    cekRole("s24") ||
                    cekRole("s29") ||
                    cekRole("s34") ||
                    cekRole("s39") ||
                    cekRole("s44") ||
                    cekRole("s49") ||
                    cekRole("s54")) && (
                    <Route
                      exact
                      path={routes.REPORT}
                      component={() => <ReportRoute />}
                    />
                  )}

              {allowedRoute !== location.pathname && (
                <Redirect to={allowedRoute} />
              )}
              {cekRole("t1") && (
                <Route
                  exact
                  path={routes.STORY}
                  component={() => <StoryRoute />}
                />
              )}
            </Switch>
          </Suspense>
        </BaseLayout>
      </PrivateRoute>
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps)(Routing);
