import routesConst from "../Routes/routes";

import Account from "../Assets/Sidebar/Nav_Account.png";
import User from "../Assets/Sidebar/Nav_User.png";
import CustomerService from "../Assets/Sidebar/support.png";
import Testimoni from "../Assets/Sidebar/Nav_Testimoni.png";
import Artikel from "../Assets/Sidebar/Nav_Artikel.png";
import Gallery from "../Assets/Sidebar/Nav_Gallery.png";
import Video from "../Assets/Sidebar/Nav_Video.png";
import Klinik from "../Assets/Sidebar/Nav_Klinik.png";
import Dokter from "../Assets/Sidebar/Nav_Dokter.png";
import Tindakan from "../Assets/Sidebar/Nav_Tindakan.png";
import Promo from "../Assets/Sidebar/Nav_Promo.png";
import FAQ from "../Assets/Sidebar/Nav_FAQ.png";
import Appointment from "../Assets/Sidebar/Nav_Appointment.png";
import Pembayaran from "../Assets/Sidebar/Nav_Pembayaran.png";
import Poin from "../Assets/Sidebar/Nav_Poin.png";
import Voucher from "../Assets/Sidebar/Nav_Voucher.png";
import VoucherOwnership from "../Assets/Sidebar/Nav_VoucherOwnership.png";
import Chat from "../Assets/Sidebar/Nav_Chat.png";
import MessageCenter from "../Assets/Sidebar/crm.svg";
import Setting from "../Assets/Sidebar/Nav_Setting.png";
import Report from "../Assets/Sidebar/Nav_Setting.png";
import Story from "../Assets/Sidebar/Nav_Story.png";
import BannerPromo from "../Assets/Sidebar/Nav_BannerPromo.png";
import OnboardingLogin from "../Assets/Sidebar/Nav_OnboardingLogin.png";
import Asuransi from "../Assets/Sidebar/Nav_Insurance.png";

export const totalPembayaran = (name, item) => {
  if (name === "subTotalFee") {
    return item.map((row) => row).reduce((sum, i) => (sum += i.subTotalFee), 0);
  } else if (name === "totalDiscount") {
    return item.map((row) => row).reduce((sum, i) => (sum += i.discountRp), 0);
  } else if (name === "totalBiaya") {
    return item.map((row) => row).reduce((sum, i) => (sum += i.totalFee), 0);
  } else if (name === "totalTerbayar") {
    return item.map((row) => row).reduce((sum, i) => (sum += i.paidFee), 0);
  }
};

export const paymentMethodPembayaran = (item) => {
  if (item) {
    if (item.substring(0, 14) === "virtualAccount") {
      return `Virtual Account ${item.substring(14)}`;
    } else {
      return item;
    }
  }
};

export const headerTitle = (item) => {
  if (item === "/account") {
    return "Account";
  } else if (item === "/user") {
    return "User";
  } else if (item === "/customer-support") {
    return "Customer Service";
  } else if (item === "/testimoni") {
    return "Testimoni";
  } else if (item === "/artikel") {
    return "Artikel";
  } else if (item === "/gallery") {
    return "Gallery";
  } else if (item === "/story") {
    return "Story";
  } else if (item === "/video") {
    return "Video";
  } else if (item === "/klinik") {
    return "Klinik";
  } else if (item === "/dokter") {
    return "Dokter";
  } else if (item === "/tindakan") {
    return "Tindakan";
  } else if (item === "/promo") {
    return "Promo";
  } else if (item === "/banner-promo") {
    return "Banner Promo";
  } else if (item === "/onboarding-login"){
    return "Onboarding Login";
  } else if (item === "/faq") {
    return "FAQ";
  } else if (item === "/asuransi") {
    return "Asuransi";
  } else if (item === "/appointment") {
    return "Appointment";
  } else if (item === "/pembayaran") {
    return "Pembayaran";
  } else if (item === "/poin") {
    return "Poin";
  } else if (item === "/voucher") {
    return "Voucher";
  } else if (item === "/voucherownership") {
    return "Voucher Ownership";
  } else if (item === "/report") {
    return "Report";
  } else {
    return "Setting";
  }
};

export const sidebar = [
  {
    link: routesConst.ACCOUNT,
    label: "Account",
    icon: <img src={Account} alt="Account" />,
  },
  {
    link: routesConst.USER,
    label: "User",
    icon: <img src={User} alt="User" />,
  },
  {
    link: routesConst.CUSTOMERSERVICE,
    label: "Customer Service",
    icon: <img src={CustomerService} alt="CustomerService" style={{ width: 30, height: 30 }} />,
  },
  {
    link: routesConst.TESTIMONI,
    label: "Testimoni",
    icon: <img src={Testimoni} alt="Testimoni" />,
  },
  {
    link: routesConst.ARTIKEL,
    label: "Artikel",
    icon: <img src={Artikel} alt="Artikel" />,
  },
  {
    link: routesConst.GALLERY,
    label: "Gallery",
    icon: <img src={Gallery} alt="Gallery" />,
  },
  {
    link: routesConst.STORY,
    label: "Story",
    icon: <img src={Story} alt="Story" style={{ width: 30, height: 30 }} />,
  },
  {
    link: routesConst.VIDEO,
    label: "Video",
    icon: <img src={Video} alt="Video" />,
  },
  {
    link: routesConst.KLINIK,
    label: "Klinik",
    icon: <img src={Klinik} alt="Klinik" />,
  },
  {
    link: routesConst.DOKTER,
    label: "Dokter",
    icon: <img src={Dokter} alt="Dokter" />,
  },
  {
    link: routesConst.TINDAKAN,
    label: "Tindakan",
    icon: <img src={Tindakan} alt="Tindakan" />,
  },
  {
    link: routesConst.PROMO,
    label: "Promo",
    icon: <img src={Promo} alt="Promo" />,
  },
  {
    link: routesConst.BANNER_PROMO,
    label: "Banner Promo",
    icon: (
      <img
        src={BannerPromo}
        alt="Banner Promo"
        style={{ width: 30, height: 30 }}
      />
    ),
  },
  // {
  //   link: routesConst.ONBOARDINGLOGIN,
  //   label: "Onboarding login",
  //   icon: (
  //     <img
  //       src={OnboardingLogin}
  //       alt="Onboarding Login"
  //       // style={{ width: 30, height: 30 }}
  //     />
  //   ),
  // },
  {
    link: routesConst.FAQ,
    label: "FAQ",
    icon: <img src={FAQ} alt="FAQ" />,
  },
  {
    link :routesConst.ASURANSI,
    label: "Asuransi",
    icon: <img src={Asuransi} alt="Asuransi" />
  },
  {
    link: routesConst.APPOINTMENT,
    label: "Appointment",
    icon: <img src={Appointment} alt="Appointment" />,
  },
  {
    link: routesConst.PEMBAYARAN,
    label: "Pembayaran",
    icon: <img src={Pembayaran} alt="Pembayaran" />,
  },
  {
    link: routesConst.POIN,
    label: "Poin",
    icon: <img src={Poin} alt="Poin" />,
  },
  {
    link: routesConst.VOUCHER,
    label: "Voucher",
    icon: <img src={Voucher} alt="Voucher" />,
  },
  {
    link: routesConst.VOUCHEROWNERSHIP,
    label: "Voucher Ownership",
    icon: <img src={VoucherOwnership} alt="Voucher Ownership" />,
  },
  {
    link: routesConst.CHAT,
    label: "Chat",
    icon: <img src={Chat} style={{ width: 30, height: 30 }} alt="Chat" />,
  },
  {
    link: routesConst.MESSAGECENTER,
    label: "Message Center",
    icon: (
      <img
        src={MessageCenter}
        style={{ width: 30, height: 30 }}
        alt="Message Center"
      />
    ),
  },
  {
    link: routesConst.REPORT,
    label: "Report",
    icon: <img src={Report} alt="Report" />,
  },
  {
    link: routesConst.SETTING,
    label: "Setting",
    icon: <img src={Setting} alt="Setting" />,
  },
];
