// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LOGIN: "/auth/login",
  FORGOTPASS: "/auth/forgotpass",
  CHANGEPASS: "/auth/changepass",
  ACCOUNT: "/account",
  USER: "/user",
  CUSTOMERSERVICE: "/customer-support",
  TESTIMONI: "/testimoni",
  ARTIKEL: "/artikel",
  GALLERY: "/gallery",
  STORY: "/story",
  VIDEO: "/video",
  KLINIK: "/klinik",
  DOKTER: "/dokter",
  TINDAKAN: "/tindakan",
  PROMO: "/promo",
  BANNER_PROMO: "/banner-promo",
  ONBOARDINGLOGIN: "/onboarding-login",
  FAQ: "/faq",
  ASURANSI: "/asuransi",
  APPOINTMENT: "/appointment",
  PEMBAYARAN: "/pembayaran",
  POIN: "/poin",
  VOUCHER: "/voucher",
  VOUCHEROWNERSHIP: "/voucherownership",
  CHAT: "/chat",
  MESSAGECENTER: "/messagecenter",
  SETTING: "/setting",
  REPORT: "/report",
};
